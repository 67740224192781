export const Content = [
    {
        categoryName: "Digital Marketing",
        services: [
            // digitaMarketing: 
            {
                query: 'digitaMarketing',
                title: 'Digital Marketing',
                subTitle: 'Driving Growth in the Digital Age ',
                p1: "In today's hyperconnected world, establishing a strong digital presence is essential for businesses of all sizes. At GDCS, we specialize in Digital Marketing, offering comprehensive solutions designed to elevate your brand, drive traffic, and maximize conversions across various online channels.",
                p2: "Our Digital Marketing service is tailored to meet the unique needs and objectives of your business. Whether you're aiming to increase brand awareness, generate leads, or boost sales, our team of experienced marketers works closely with you to develop a customized strategy that aligns with your goals and target audience.",
                p3: "From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing and content creation, we leverage a combination of data-driven insights and creative expertise to deliver results-driven campaigns that drive tangible business outcomes.",
                p4: "With our Digital Marketing service, you gain access to a diverse range of tactics and channels, from search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing and content creation. We leverage data-driven insights and industry best practices to optimize your campaigns for maximum impact and ROI. Moreover, our commitment to transparency and accountability means that you receive regular reports and updates, allowing you to track the performance of your campaigns and make informed decisions.",
                p5: "Whether you're a startup looking to establish a digital presence or an established enterprise seeking to expand your online reach, GDCS is your trusted partner for Digital Marketing success. Let us help you unlock the full potential of your digital strategy and achieve your business objectives.",
                p6: "",
                cardHeading: 'Key features of our Digital Marketing service include:',
                c1Front: 'Strategic Planning',
                c1Back: ' We begin by conducting a thorough analysis of your industry, competitors, and target audience to develop a strategic roadmap that guides our digital marketing efforts.',
                c2Front: 'Search Engine Optimization (SEO)',
                c2Back: "Our SEO strategies are designed to improve your website's visibility in search engine results, driving organic traffic and enhancing your online presence.",
                c3Front: 'Social Media Marketing',
                c3Back: 'From creating engaging content to managing ad campaigns, we help you leverage the power of social media platforms to connect with your audience, build relationships, and drive conversions.',
                c4Front: 'Content Creation',
                c4Back: 'Compelling content is at the heart of effective digital marketing. Our team develops high-quality, engaging content that resonates with your audience and reinforces your brand messaging.',
                ilu1PathLight: 'Digital Marketing 1 bw.gif',
                ilu1PathDark: 'Digital Marketing 1 bb.gif',
                ilu2PathLight: 'Digital Marketing 2 bw.gif',
                ilu2PathDark: 'Digital Marketing 2 bb.gif',
            },
            // AnimationProduct: 
            {
                query: 'AnimationProduct',
                title: 'Product Animation',
                subTitle: "Unveiling Your Product's Story",
                p1: "Elevate your product presentation with GDCS's Product Animation/Packaging service. Whether you're launching a new product, showcasing its features, or demonstrating its functionality, our team specializes in creating dynamic animations that bring your products to life and leave a lasting impression on your audience.",
                p2: "Our Product Animation/Packaging service offers tailored solutions to meet the diverse needs and objectives of businesses across various industries. From sleek and sophisticated animations for luxury goods to playful and engaging presentations for consumer products, we have the expertise and creativity to showcase your products in the best possible light.",
                p3: "With a focus on detail, realism, and visual appeal, we meticulously animate every aspect of your product, from its design and functionality to its packaging and branding. Whether you need 3D renderings for e-commerce platforms, instructional videos for assembly or usage, or promotional animations for marketing campaigns, our team is dedicated to delivering animations that captivate, inform, and inspire.",
                p4: "With our Product Animation/Packaging service, you can breathe life into your product presentations, making them more compelling and memorable. From 3D renderings that showcase every detail to interactive animations that demonstrate functionality, our team of skilled animators leverages cutting-edge technology to create visuals that leave a lasting impression. Moreover, we offer expertise in packaging design, ensuring that your product packaging stands out on the shelves and reinforces your brand identity.",
                p5: "Whether you're launching a new product, revamping your packaging, or seeking to enhance your product presentation, GDCS is your trusted partner for professional Product Animation/Packaging. Let us help you showcase your products in the best possible light and drive engagement, interest, and sales.",
                p6: "",
                cardHeading: "Key features of our Product Animation/Packaging service include",
                c1Front: 'Customized Solutions',
                c1Back: "We collaborate closely with you to understand your product's unique features, target audience, and branding guidelines, ensuring that our animations align seamlessly with your vision and objectives.",
                c2Front: 'Realistic Visuals',
                c2Back: "Using advanced 3D rendering techniques, we create lifelike animations that accurately represent your product's design, materials, and functionality, providing a compelling and immersive viewing experience for your audience.",
                c3Front: 'Packaging Design',
                c3Back: "In addition to product animations, we specialize in creating stunning visuals for packaging design, including product labels, boxes, and wrappers, that enhance your brand identity and stand out on the shelves.",
                c4Front: 'Interactive Presentations',
                c4Back: " We can develop interactive presentations and virtual tours that allow your audience to explore your product from every angle, engage with its features, and gain a deeper understanding of its value proposition.",
                ilu1PathLight: 'Product packaging 1 bw.gif',
                ilu1PathDark: 'Product packaging 1 bb.gif',
                ilu2PathLight: 'Product packaging 2 bw.gif',
                ilu2PathDark: 'Product packaging 2 bb.gif',
            },

            // {
            //     title:"Pay-Per-Click (PPC) Advertising",
            //     subTitle: "Driving Instant Traffic and Conversions",
            //     p1: "Maximize your online visibility and drive targeted traffic to your website with GDCS's Pay-Per-Click (PPC) Advertising service. Whether you're looking to boost brand awareness, generate leads, or increase sales, our team of PPC specialists is dedicated to creating and managing high-performance campaigns that deliver measurable results.",
            //     p2: "Our PPC Advertising service offers a comprehensive approach to digital marketing, leveraging data-driven insights and advanced targeting techniques to reach your ideal audience at the right time with the right message. From keyword research and ad creation to campaign optimization and performance tracking, we provide end-to-end solutions that ensure your ads are effective, efficient, and aligned with your business goals.",
            //     p3: "Our PPC Marketing service is designed to meet the diverse needs of businesses across various industries. Whether you're launching a new product, promoting a special offer, or seeking to enhance brand awareness, our team of experienced marketers collaborates closely with you to develop tailored PPC campaigns that align with your business goals. From keyword research and ad creation to bid management and performance analysis, we handle every aspect of your PPC campaigns to ensure optimal results.",
            //     p4: "With GDCS, you're not just investing in ads – you're investing in a partnership dedicated to driving your business growth. We continually monitor and refine your campaigns, using data-driven insights to improve performance and achieve your desired outcomes. Let GDCS be your partner in PPC Marketing, and together, let's harness the power of targeted advertising to propel your business to new heights.",
            //     p5:"Whether you're launching a new product, driving traffic to your website, or enhancing your online presence, GDCS is your trusted partner for professional PPC Advertising. Let us help you reach your target audience, achieve your marketing objectives, and grow your business through strategic and effective PPC campaigns.",
            //     cardHeading: "Key features of our PPC Advertising service include:",
            //     c1Front: "Customized Campaigns",
            //     c1Back: "We work closely with you to understand your business objectives, target audience, and budget, creating tailored PPC campaigns that align with your goals and maximize your return on investment (ROI).",
            //     c2Front: "Keyword Research",
            //     c2Back: "Our team conducts thorough keyword research to identify the most relevant and high-performing keywords for your industry, ensuring that your ads reach the right audience and drive qualified traffic to your website.",
            //     c3Front: "Ad Creation",
            //     c3Back: "We design compelling ad copy and visuals that capture attention, convey your value proposition, and encourage clicks. Our ads are optimized for various platforms, including Google Ads, Bing Ads, and social media channels.",
            //     c4Front: "Targeted Advertising",
            //     c4Back: "Using advanced targeting options, we ensure your ads are shown to the right people based on factors such as location, demographics, interests, and search behavior, increasing the likelihood of conversions.",
            //     ilu1PathLight: "ppc 1 light.gif",
            //     ilu1PathDark: "ppc 1 dark.gif",
            //     ilu2PathLight: "ppc 2 light.gif",
            //     ilu2PathDark: "ppc 2 dark.gif"
            // },

            {
                title:"Online Reputation Management(ORM)",
                subTitle: "Building and Protecting Your Digital Image",
                p1: "Protect and enhance your brand's online presence with GDCS's comprehensive Online Reputation Management (ORM) service. In today's digital age, maintaining a positive online reputation is crucial for building trust, attracting customers, and achieving long-term success. Our ORM experts are dedicated to monitoring, managing, and improving your online image, ensuring that your brand is perceived positively across all digital channels.",
                p2: "Our ORM service offers a strategic approach to managing your online reputation, combining proactive monitoring, timely responses, and effective reputation-building techniques. Whether you're a small business, a large enterprise, or an individual professional, we provide tailored solutions to address your unique needs and challenges.",
                p3: "In today's digital age, your online reputation can significantly impact your business's success. At GDCS, our Online Reputation Management (ORM) service is designed to help you build, manage, and protect your brand's digital image. Whether you're looking to enhance your online presence, recover from negative publicity, or maintain a positive reputation, our team of experts is dedicated to crafting strategies that align with your goals and resonate with your audience. By monitoring, analyzing, and addressing online feedback, we ensure that your brand's reputation remains strong and favorable.",
                p4: "Our ORM service encompasses a comprehensive approach to managing your online reputation across various platforms, including social media, review sites, and search engines. We proactively engage with your audience, respond to reviews, and implement strategies to highlight positive content while mitigating the impact of negative feedback. By leveraging advanced tools and data-driven insights, we provide you with transparent reporting and actionable recommendations, allowing you to make informed decisions and maintain a positive digital presence. Let GDCS be your partner in Online Reputation Management, and together, let's safeguard your brand's reputation and drive long-term success.",
                p5:"Whether you're looking to protect your brand, improve public perception, or recover from a reputation setback, GDCS is your trusted partner for professional Online Reputation Management. Let us help you build, maintain, and enhance a positive online presence that drives trust, loyalty, and business growth.",
                cardHeading: "Key features of our Online Reputation Management service include:",
                c1Front: "Reputation Monitoring",
                c1Back: " We continuously monitor your online presence across social media platforms, review sites, forums, and other digital channels to identify any mentions, reviews, or comments about your brand. This allows us to stay informed about your online reputation in real time.",
                c2Front: "Crisis Management:",
                c2Back: " In the event of a reputation crisis, our team is equipped to handle it swiftly and effectively. We develop and implement crisis management strategies to mitigate damage, manage public perception, and restore your reputation.",
                c3Front: "Content Creation",
                c3Back: "We create and promote high-quality, positive content that reinforces your brand values and showcases your expertise. This includes blog posts, articles, press releases, and social media content that helps build a positive online narrative.",
                c4Front: "Social Media Management",
                c4Back: "Our team manages your social media profiles, ensuring that your brand engages with your audience effectively and positively. We develop and implement social media strategies that align with your brand identity and reputation goals.",
                ilu1PathLight: "orm 1 light.gif",
                ilu1PathDark: "orm 1 dark.gif",
                ilu2PathLight: "orm 2 light.gif",
                ilu2PathDark: "orm 2 dark.gif"
            }
        ]
    },

    {
        categoryName: "Application Development",
        services: [
            // DynamicDesktop: 
            {
                query: 'DynamicDesktop',
                title: 'Desktop App Developing',
                subTitle: 'Crafting Your Digital Presence',
                p1: 'Empower your business with versatile desktop applications that seamlessly adapt to your evolving needs. At GDCS, we specialize in Dynamic Desktop App Building, offering tailored solutions that combine cutting-edge technology with intuitive design to enhance productivity and drive innovation.',
                p2: 'Our Dynamic Desktop App Building service is designed to meet the diverse requirements of modern businesses across various industries. Whether you need to streamline internal processes, enhance collaboration, or deliver exceptional user experiences, our team of experienced developers works closely with you to understand your unique challenges and objectives.',
                p3: "From sleek and responsive user interfaces to robust functionalities and seamless performance, we prioritize quality and innovation at every stage of the development process. Leveraging the latest technologies and industry best practices, we ensure that your desktop application not only meets but exceeds the expectations of your users.",
                p4: "In the realm of digital solutions, desktop applications remain a cornerstone for businesses seeking efficiency and innovation. At GDCS, our Dynamic Desktop App Building service offers a bespoke approach to crafting applications tailored to your unique needs. Whether you're streamlining internal processes, enhancing productivity, or engaging customers with intuitive interfaces, our team of experienced developers collaborates closely with you to conceptualize, design, and develop desktop applications that exceed expectations.",
                p5: "Whether you're a startup looking to establish a strong digital presence or an established enterprise seeking to modernize your desktop applications, GDCS is your trusted partner for Dynamic Desktop App Building. Let us help you harness the power of desktop technology and unlock new opportunities for growth and success.",
                p6: "",
                cardHeading: 'Key features of our Dynamic Desktop App Building service include:',
                c1Front: 'Customization',
                c1Back: 'We understand that every business has its own unique requirements. Our team crafts customized solutions tailored to your specific needs, branding, and user preferences.',
                c2Front: 'Scalability',
                c2Back: 'As your business grows, so should your desktop application. We build scalable architectures that accommodate future updates, expansions, and user growth without compromising performance.',
                c3Front: 'Integration',
                c3Back: 'Our desktop applications seamlessly integrate with existing systems and third-party tools, providing a cohesive ecosystem that enhances efficiency and productivity.',
                c4Front: 'Security',
                c4Back: 'We prioritize the security of your data and sensitive information. Our desktop applications adhere to the highest standards of security protocols to ensure that your data remains safe and protected.',
                lastP: "Whether you're a startup looking to establish a strong digital presence or an established enterprise seeking to modernize your desktop applications, GDCS is your trusted partner for Dynamic Desktop App Building. Let us help you harness the power of desktop technology and unlock new opportunities for growth and success.",
                ilu1PathLight: 'Desktop 1 bw.gif',
                ilu1PathDark: 'Desktop 1 bb.gif',
                ilu2PathLight: 'Desktop 2 bw.gif',
                ilu2PathDark: 'Desktop 2 bb.gif',
            },
            // MobileApp: 
            {
                query: 'MobileApp',
                title: 'App Development',
                subTitle: 'Empowering Your Mobile Strategy',
                p1: "In today's mobile-centric world, a strong digital presence extends beyond websites to include intuitive and engaging mobile applications. At GDCS, we specialize in Mobile Application Development, offering tailored solutions that bring your ideas to life on iOS, Android, and cross-platform environments.",
                p2: "Our approach to Mobile Application Development begins with a deep understanding of your business objectives, target audience, and user needs. Whether you're looking to streamline internal processes, enhance customer engagement, or launch a new product, our team collaborates closely with you to conceptualize, design, and develop mobile applications that meet your specific requirements.",
                p3: "From sleek and user-friendly interfaces to powerful functionalities and seamless performance, we prioritize quality and innovation at every stage of the development process. Leveraging the latest technologies and industry best practices, we ensure that your mobile application not only meets but exceeds the expectations of your users.",
                p4: "In an era dominated by mobile devices, having a strong mobile presence is essential for reaching and engaging your audience. With GDCS's Mobile Application Development service, you can harness the full potential of mobile technology to connect with your customers wherever they are. Whether you're launching a new app or optimizing an existing one, our team of skilled developers is dedicated to creating user-friendly and feature-rich mobile experiences that drive results. From intuitive interfaces to seamless performance, we prioritize quality and innovation to ensure that your app stands out in the crowded app marketplace. Partner with us for Mobile Application Development, and let's turn your mobile vision into reality, empowering your brand to thrive in the digital world.",
                p5: "Whether you're a startup venturing into the mobile space or an established enterprise seeking to enhance your digital presence, GDCS is your trusted partner for Mobile Application Development. Let us help you harness the power of mobile technology and take your business to new heights.",
                p6: "",
                cardHeading: 'Key features of our Mobile Application Development service include:',
                c1Front: 'Platform Compatibility',
                c1Back: "Whether you're targeting iOS, Android, or both, we develop native and cross-platform applications that deliver a consistent experience across devices.",
                c2Front: 'Scalability',
                c2Back: ' As your business grows, so should your mobile application. We build scalable architectures that accommodate future updates, expansions, and user growth without compromising performance.',
                c3Front: 'Customized Solutions',
                c3Back: 'No two businesses are alike, and neither are their mobile applications. We tailor our solutions to align with your unique goals, branding, and user preferences.',
                c4Front: 'Security',
                c4Back: 'We understand the importance of data security and privacy. Our mobile applications adhere to the highest standards of security protocols to protect your sensitive information and user data.',
                ilu1PathLight: 'App1 bw.gif',
                ilu1PathDark: 'App 1 bb.gif',
                ilu2PathLight: 'App 2 bw.gif',
                ilu2PathDark: 'App 2 bb.gif',
            }
        ]
    },

    {
        categoryName: "SEO",
        services: [
            // // SEO: 
            {
                query: 'SEO',
                title: 'Search Engine Optimization',
                subTitle: 'Crafting Your Digital Presence',
                p1: "SEO is crucial in today’s digital landscape as it helps businesses stand out in a crowded online marketplace. A well-executed SEO strategy can lead to long-term benefits, including higher search rankings, increased website traffic, better user experience, and ultimately, more conversions and sales. By staying updated with the latest SEO trends and algorithm changes, businesses can maintain and improve their online visibility, ensuring sustained growth and success.",
                p2: 'Our company offers top-notch SEO services to enhance your product is visibility and usability. We specialize in optimizing your website to rank higher on search engine results pages, attracting more organic traffic. By leveraging advanced keyword research, on-page and off-page SEO strategies, and technical optimization, we ensure your product reaches the right audience. Our expert team creates compelling content and builds high-quality backlinks to boost your site’s authority. Partner with us to make your product more accessible and engaging, driving increased user engagement and higher conversion rates. Let us help your business grow with effective SEO solutions.',
                p3: "SEO enhances your website's visibility, driving more organic traffic and potential customers. It improves user experience, boosts credibility, and increases conversion rates. By targeting relevant keywords, SEO ensures your site appears in search results, leading to long-term growth and higher return on investment for your business.",
                p4: "In the digital landscape where first impressions are paramount, your website stands as the virtual storefront of your brand. At GDCS, our Dynamic Web Building service goes beyond merely constructing websites; we sculpt digital experiences that resonate with your audience, leaving a lasting impact. From sleek designs to intuitive navigation, every element is meticulously crafted to reflect your brand identity and captivate visitors. Moreover, our commitment to innovation ensures that your website remains adaptable and scalable, evolving alongside your business to meet the demands of tomorrow. With our Dynamic Web Building service, you're not just getting a website – you're getting a powerful tool that propels your brand forward in the digital age.",
                p5: "At GDCS, we believe that your website is more than just a digital storefront – it's a reflection of your brand's identity and values. Let us empower your online presence with our SEO service and propel your business to new heights.",
                p6: "",
                cardHeading: 'Key features of our SEO service include',
                c1Front: 'Global SEO',
                c1Back: 'We offer highly effective global SEO services so that you can bring clients for your business from the different corners of the world',
                c2Front: 'National SEO',
                c2Back: 'Avail our national SEO services to achieve good ranking along with successful campaigns. Join your hands with us to establish your business as a BRAND',
                c3Front: 'Local SEO',
                c3Back: "Connect with your prospective local audience with our result-driven local SEO services to boost the traffic of your website",
                c4Front: 'Scalability',
                c4Back: 'As your business grows, so should your website. We build scalable architectures that accommodate future updates, expansions, and increases in traffic without compromising performance or user experience.',
                ilu1PathLight: 'Web 1 bw.gif',
                ilu1PathDark: 'Web 1 bb.gif',
                ilu2PathLight: 'Web 2 bw.gif',
                ilu2PathDark: 'Web 2 bb.gif',
            },
            // {
            //     query: 'Email Marketing',
            //     title: 'Email Marketing',
            //     subTitle: 'Crafting Your Digital Presence',
            //     p1: "SEO is crucial in today’s digital landscape as it helps businesses stand out in a crowded online marketplace. A well-executed SEO strategy can lead to long-term benefits, including higher search rankings, increased website traffic, better user experience, and ultimately, more conversions and sales. By staying updated with the latest SEO trends and algorithm changes, businesses can maintain and improve their online visibility, ensuring sustained growth and success.",
            //     p2: 'Our company offers top-notch SEO services to enhance your product is visibility and usability. We specialize in optimizing your website to rank higher on search engine results pages, attracting more organic traffic. By leveraging advanced keyword research, on-page and off-page SEO strategies, and technical optimization, we ensure your product reaches the right audience. Our expert team creates compelling content and builds high-quality backlinks to boost your site’s authority. Partner with us to make your product more accessible and engaging, driving increased user engagement and higher conversion rates. Let us help your business grow with effective SEO solutions.',
            //     p3: "SEO enhances your website's visibility, driving more organic traffic and potential customers. It improves user experience, boosts credibility, and increases conversion rates. By targeting relevant keywords, SEO ensures your site appears in search results, leading to long-term growth and higher return on investment for your business.",
            //     p4: "In the digital landscape where first impressions are paramount, your website stands as the virtual storefront of your brand. At GDCS, our Dynamic Web Building service goes beyond merely constructing websites; we sculpt digital experiences that resonate with your audience, leaving a lasting impact. From sleek designs to intuitive navigation, every element is meticulously crafted to reflect your brand identity and captivate visitors. Moreover, our commitment to innovation ensures that your website remains adaptable and scalable, evolving alongside your business to meet the demands of tomorrow. With our Dynamic Web Building service, you're not just getting a website – you're getting a powerful tool that propels your brand forward in the digital age.",
            //     p5: "At GDCS, we believe that your website is more than just a digital storefront – it's a reflection of your brand's identity and values. Let us empower your online presence with our SEO service and propel your business to new heights.",
            //     p6: "",
            //     cardHeading: 'Key features of our SEO service include',
            //     c1Front: 'Global SEO',
            //     c1Back: 'We offer highly effective global SEO services so that you can bring clients for your business from the different corners of the world',
            //     c2Front: 'National SEO',
            //     c2Back: 'Avail our national SEO services to achieve good ranking along with successful campaigns. Join your hands with us to establish your business as a BRAND',
            //     c3Front: 'Local SEO',
            //     c3Back: "Connect with your prospective local audience with our result-driven local SEO services to boost the traffic of your website",
            //     c4Front: 'Scalability',
            //     c4Back: 'As your business grows, so should your website. We build scalable architectures that accommodate future updates, expansions, and increases in traffic without compromising performance or user experience.',
            //     ilu1PathLight: 'Web 1 bw.gif',
            //     ilu1PathDark: 'Web 1 bb.gif',
            //     ilu2PathLight: 'Web 2 bw.gif',
            //     ilu2PathDark: 'Web 2 bb.gif',
            // },
            {
                query: 'Social Media Marketing',
                title: 'Social Media Marketing',
                subTitle: 'Crafting Your Digital Presence',
                p1: "SEO is crucial in today’s digital landscape as it helps businesses stand out in a crowded online marketplace. A well-executed SEO strategy can lead to long-term benefits, including higher search rankings, increased website traffic, better user experience, and ultimately, more conversions and sales. By staying updated with the latest SEO trends and algorithm changes, businesses can maintain and improve their online visibility, ensuring sustained growth and success.",
                p2: 'Our company offers top-notch SEO services to enhance your product is visibility and usability. We specialize in optimizing your website to rank higher on search engine results pages, attracting more organic traffic. By leveraging advanced keyword research, on-page and off-page SEO strategies, and technical optimization, we ensure your product reaches the right audience. Our expert team creates compelling content and builds high-quality backlinks to boost your site’s authority. Partner with us to make your product more accessible and engaging, driving increased user engagement and higher conversion rates. Let us help your business grow with effective SEO solutions.',
                p3: "SEO enhances your website's visibility, driving more organic traffic and potential customers. It improves user experience, boosts credibility, and increases conversion rates. By targeting relevant keywords, SEO ensures your site appears in search results, leading to long-term growth and higher return on investment for your business.",
                p4: "In the digital landscape where first impressions are paramount, your website stands as the virtual storefront of your brand. At GDCS, our Dynamic Web Building service goes beyond merely constructing websites; we sculpt digital experiences that resonate with your audience, leaving a lasting impact. From sleek designs to intuitive navigation, every element is meticulously crafted to reflect your brand identity and captivate visitors. Moreover, our commitment to innovation ensures that your website remains adaptable and scalable, evolving alongside your business to meet the demands of tomorrow. With our Dynamic Web Building service, you're not just getting a website – you're getting a powerful tool that propels your brand forward in the digital age.",
                p5: "At GDCS, we believe that your website is more than just a digital storefront – it's a reflection of your brand's identity and values. Let us empower your online presence with our SEO service and propel your business to new heights.",
                p6: "",
                cardHeading: 'Key features of our SEO service include',
                c1Front: 'Global SEO',
                c1Back: 'We offer highly effective global SEO services so that you can bring clients for your business from the different corners of the world',
                c2Front: 'National SEO',
                c2Back: 'Avail our national SEO services to achieve good ranking along with successful campaigns. Join your hands with us to establish your business as a BRAND',
                c3Front: 'Local SEO',
                c3Back: "Connect with your prospective local audience with our result-driven local SEO services to boost the traffic of your website",
                c4Front: 'Scalability',
                c4Back: 'As your business grows, so should your website. We build scalable architectures that accommodate future updates, expansions, and increases in traffic without compromising performance or user experience.',
                ilu1PathLight: 'Web 1 bw.gif',
                ilu1PathDark: 'Web 1 bb.gif',
                ilu2PathLight: 'Web 2 bw.gif',
                ilu2PathDark: 'Web 2 bb.gif',
            },
            {
                query: 'Digital Strategy and Planning',
                title: 'Digital Strategy and Planning',
                subTitle: 'Crafting Your Digital Presence',
                p1: "SEO is crucial in today’s digital landscape as it helps businesses stand out in a crowded online marketplace. A well-executed SEO strategy can lead to long-term benefits, including higher search rankings, increased website traffic, better user experience, and ultimately, more conversions and sales. By staying updated with the latest SEO trends and algorithm changes, businesses can maintain and improve their online visibility, ensuring sustained growth and success.",
                p2: 'Our company offers top-notch SEO services to enhance your product is visibility and usability. We specialize in optimizing your website to rank higher on search engine results pages, attracting more organic traffic. By leveraging advanced keyword research, on-page and off-page SEO strategies, and technical optimization, we ensure your product reaches the right audience. Our expert team creates compelling content and builds high-quality backlinks to boost your site’s authority. Partner with us to make your product more accessible and engaging, driving increased user engagement and higher conversion rates. Let us help your business grow with effective SEO solutions.',
                p3: "SEO enhances your website's visibility, driving more organic traffic and potential customers. It improves user experience, boosts credibility, and increases conversion rates. By targeting relevant keywords, SEO ensures your site appears in search results, leading to long-term growth and higher return on investment for your business.",
                p4: "In the digital landscape where first impressions are paramount, your website stands as the virtual storefront of your brand. At GDCS, our Dynamic Web Building service goes beyond merely constructing websites; we sculpt digital experiences that resonate with your audience, leaving a lasting impact. From sleek designs to intuitive navigation, every element is meticulously crafted to reflect your brand identity and captivate visitors. Moreover, our commitment to innovation ensures that your website remains adaptable and scalable, evolving alongside your business to meet the demands of tomorrow. With our Dynamic Web Building service, you're not just getting a website – you're getting a powerful tool that propels your brand forward in the digital age.",
                p5: "At GDCS, we believe that your website is more than just a digital storefront – it's a reflection of your brand's identity and values. Let us empower your online presence with our SEO service and propel your business to new heights.",
                p6: "",
                cardHeading: 'Key features of our SEO service include',
                c1Front: 'Global SEO',
                c1Back: 'We offer highly effective global SEO services so that you can bring clients for your business from the different corners of the world',
                c2Front: 'National SEO',
                c2Back: 'Avail our national SEO services to achieve good ranking along with successful campaigns. Join your hands with us to establish your business as a BRAND',
                c3Front: 'Local SEO',
                c3Back: "Connect with your prospective local audience with our result-driven local SEO services to boost the traffic of your website",
                c4Front: 'Scalability',
                c4Back: 'As your business grows, so should your website. We build scalable architectures that accommodate future updates, expansions, and increases in traffic without compromising performance or user experience.',
                ilu1PathLight: 'Web 1 bw.gif',
                ilu1PathDark: 'Web 1 bb.gif',
                ilu2PathLight: 'Web 2 bw.gif',
                ilu2PathDark: 'Web 2 bb.gif',
            },
            {
                query: 'Web Analytics',
                title: 'Web Analytics',
                subTitle: 'Crafting Your Digital Presence',
                p1: "SEO is crucial in today’s digital landscape as it helps businesses stand out in a crowded online marketplace. A well-executed SEO strategy can lead to long-term benefits, including higher search rankings, increased website traffic, better user experience, and ultimately, more conversions and sales. By staying updated with the latest SEO trends and algorithm changes, businesses can maintain and improve their online visibility, ensuring sustained growth and success.",
                p2: 'Our company offers top-notch SEO services to enhance your product is visibility and usability. We specialize in optimizing your website to rank higher on search engine results pages, attracting more organic traffic. By leveraging advanced keyword research, on-page and off-page SEO strategies, and technical optimization, we ensure your product reaches the right audience. Our expert team creates compelling content and builds high-quality backlinks to boost your site’s authority. Partner with us to make your product more accessible and engaging, driving increased user engagement and higher conversion rates. Let us help your business grow with effective SEO solutions.',
                p3: "SEO enhances your website's visibility, driving more organic traffic and potential customers. It improves user experience, boosts credibility, and increases conversion rates. By targeting relevant keywords, SEO ensures your site appears in search results, leading to long-term growth and higher return on investment for your business.",
                p4: "In the digital landscape where first impressions are paramount, your website stands as the virtual storefront of your brand. At GDCS, our Dynamic Web Building service goes beyond merely constructing websites; we sculpt digital experiences that resonate with your audience, leaving a lasting impact. From sleek designs to intuitive navigation, every element is meticulously crafted to reflect your brand identity and captivate visitors. Moreover, our commitment to innovation ensures that your website remains adaptable and scalable, evolving alongside your business to meet the demands of tomorrow. With our Dynamic Web Building service, you're not just getting a website – you're getting a powerful tool that propels your brand forward in the digital age.",
                p5: "At GDCS, we believe that your website is more than just a digital storefront – it's a reflection of your brand's identity and values. Let us empower your online presence with our SEO service and propel your business to new heights.",
                p6: "",
                cardHeading: 'Key features of our SEO service include',
                c1Front: 'Global SEO',
                c1Back: 'We offer highly effective global SEO services so that you can bring clients for your business from the different corners of the world',
                c2Front: 'National SEO',
                c2Back: 'Avail our national SEO services to achieve good ranking along with successful campaigns. Join your hands with us to establish your business as a BRAND',
                c3Front: 'Local SEO',
                c3Back: "Connect with your prospective local audience with our result-driven local SEO services to boost the traffic of your website",
                c4Front: 'Scalability',
                c4Back: 'As your business grows, so should your website. We build scalable architectures that accommodate future updates, expansions, and increases in traffic without compromising performance or user experience.',
                ilu1PathLight: 'Web 1 bw.gif',
                ilu1PathDark: 'Web 1 bb.gif',
                ilu2PathLight: 'Web 2 bw.gif',
                ilu2PathDark: 'Web 2 bb.gif',
            },
        ]
    },

    {
        categoryName: 'Web design',
        services: [
            // // DynamicWeb: 
            {
                query: 'DynamicWeb',
                title: 'Website Development',
                subTitle: 'Crafting Your Digital Presence',
                p1: "Establish a strong online presence and engage your audience with GDCS's professional Website Development service. Whether you're a small business, startup, or enterprise, we specialize in creating custom websites that not only look stunning but also deliver exceptional user experiences and drive measurable results.",
                p2: 'Our Website Development service offers tailored solutions to meet the diverse needs and objectives of businesses across various industries. From simple brochure websites to complex e-commerce platforms and web applications, we have the expertise and experience to bring your vision to life on the digital canvas.',
                p3: "With a focus on functionality, aesthetics, and performance, we design and develop websites that reflect your brand identity, showcase your products or services, and effectively communicate your message to your target audience. Whether you're looking to attract new customers, generate leads, or streamline your online operations, our team of skilled developers is dedicated to delivering websites that exceed your expectations.",
                p4: "In the digital landscape where first impressions are paramount, your website stands as the virtual storefront of your brand. At GDCS, our Dynamic Web Building service goes beyond merely constructing websites; we sculpt digital experiences that resonate with your audience, leaving a lasting impact. From sleek designs to intuitive navigation, every element is meticulously crafted to reflect your brand identity and captivate visitors. Moreover, our commitment to innovation ensures that your website remains adaptable and scalable, evolving alongside your business to meet the demands of tomorrow. With our Dynamic Web Building service, you're not just getting a website – you're getting a powerful tool that propels your brand forward in the digital age.",
                p5: "At GDCS, we believe that your website is more than just a digital storefront – it's a reflection of your brand's identity and values. Let us empower your online presence with our Dynamic Web Building service and propel your business to new heights.",
                p6: "",
                cardHeading: 'Key features of our Website Development service include:',
                c1Front: 'Customized Solutions',
                c1Back: 'We work closely with you to understand your business goals, target audience, and design preferences, ensuring that our websites align seamlessly with your brand identity and objectives.',
                c2Front: 'Responsive Design',
                c2Back: 'Our websites are built with responsive design principles, ensuring that they look and perform flawlessly across all devices and screen sizes, from desktops and laptops to smartphones and tablets.',
                c3Front: 'E-commerce Functionality',
                c3Back: "If you're selling products or services online, we can integrate robust e-commerce solutions that facilitate transactions, manage inventory, and streamline the buying process for your customers.",
                c4Front: 'Scalability',
                c4Back: 'As your business grows, so should your website. We build scalable architectures that accommodate future updates, expansions, and increases in traffic without compromising performance or user experience.',
                ilu1PathLight: 'Web 1 bw.gif',
                ilu1PathDark: 'Web 1 bb.gif',
                ilu2PathLight: 'Web 2 bw.gif',
                ilu2PathDark: 'Web 2 bb.gif',
            },

        ]
    },

    {
        categoryName: 'Animation',
        services: [
            // animation3D: 
            {
                query: 'animation3D',
                title: '3D/2D Animation',
                subTitle: 'Bringing Ideas to Life',
                p1: 'Bring your ideas to life with captivating 3D and 2D animations from GDCS. Our Animation service offers a perfect blend of creativity, technical expertise, and storytelling, allowing you to engage your audience and convey complex concepts in a visually compelling manner.',
                p2: "Whether you're looking to create stunning visual effects, educational videos, promotional materials, or interactive presentations, our team of talented animators and designers is equipped with the skills and tools to bring your vision to reality.",
                p3: "With a focus on quality and innovation, we tailor our animation solutions to suit your specific needs and objectives. From character animation and motion graphics to product visualization and architectural walkthroughs, we leverage the latest software and techniques to deliver animations that captivate, inform, and inspire.",
                p4: "Animation is a powerful medium for storytelling, allowing you to convey complex ideas and evoke emotions in ways that traditional media cannot. At GDCS, our Animation service offers a gateway to creativity, enabling you to bring your concepts to life with stunning visuals and dynamic motion. Whether you're producing marketing videos, educational content, or entertainment pieces, our team of talented animators is committed to delivering animations that captivate and inspire your audience. With a focus on creativity and technical excellence, we leverage the latest tools and techniques to craft animations that leave a lasting impression and drive engagement. Let GDCS be your partner in animation, and together, let's transform your ideas into captivating visual stories that resonate with your audience.",
                p5: "Whether you're a business looking to enhance your marketing efforts, an educator aiming to engage students, or a creative professional seeking to bring your vision to life, GDCS is your trusted partner for 3D/2D Animation excellence. Let us help you unlock the power of animation and make your ideas shine.",
                p6: "",
                cardHeading: 'Key features of our 3D/2D Animation service include:',
                c1Front: 'Customized Solutions',
                c1Back: 'We work closely with you to understand your goals and requirements, ensuring that our animations align with your brand identity and messaging.',
                c2Front: 'Creative Storytelling',
                c2Back: "Whether it's a short film, explainer video, or interactive presentation, we employ creative storytelling techniques to convey your message effectively and leave a lasting impression on your audience.",
                c3Front: 'High-Quality Visuals',
                c3Back: 'Our animations are crafted with attention to detail and precision, resulting in stunning visuals that showcase your products, concepts, or ideas in the best possible light.',
                c4Front: 'Versatility',
                c4Back: 'From 3D character animation to 2D motion graphics, we have the expertise to handle a wide range of animation styles and techniques to suit your project requirements.',
                ilu1PathLight: 'Animation 1 bw.gif',
                ilu1PathDark: 'Animation 1 bb.gif',
                ilu2PathLight: 'Animation 2 bw.gif',
                ilu2PathDark: 'Animation 2 bb.gif',
            },

            // VdoEdit: 
            {
                query: 'VdoEdit',
                title: 'Video Editing',
                subTitle: 'Transforming Vision into Reality',
                p1: "Transform your raw footage into polished masterpieces with GDCS's professional Video Editing service. Whether you're a content creator, filmmaker, business owner, or marketer, our team of skilled editors is dedicated to bringing your vision to life and creating impactful videos that captivate your audience.",
                p2: 'With a keen eye for detail and a commitment to excellence, we meticulously edit your footage to enhance visual appeal, storytelling, and overall production quality. From trimming and cutting to adding effects, transitions, and audio enhancements, we leverage industry-leading software and techniques to ensure that your videos stand out and leave a lasting impression.',
                p3: "With a keen eye for detail and a commitment to excellence, we meticulously edit your footage to enhance visual appeal, storytelling, and overall production quality. From trimming and cutting to adding effects, transitions, and audio enhancements, we leverage industry-leading software and techniques to ensure that your videos stand out and leave a lasting impression.",
                p4: "In the age of video content, the editing process is where raw footage transforms into a polished masterpiece. GDCS's Video Editing service offers the expertise and creativity needed to transform your vision into reality. Whether you're a filmmaker, content creator, or business owner, our team of skilled editors collaborates with you to bring your story to life with precision and flair. From cutting-edge effects to seamless transitions, we meticulously edit every frame to ensure that your video captures attention and communicates your message effectively. With our Video Editing service, you can trust that your content will stand out in a crowded digital landscape, driving engagement and inspiring action among your audience.",
                p5: "Whether you're looking to enhance your brand's storytelling, engage your audience, or drive conversions, GDCS is your trusted partner for professional Video Editing. Let us help you bring your videos to life and achieve your marketing and communication objectives with precision and creativity.",
                p6: "",
                cardHeading: 'Key features of our Video Editing service include',
                c1Front: 'Creative Collaboration',
                c1Back: 'We collaborate closely with you to understand your creative vision, style preferences, and project goals, ensuring that our edits align with your expectations and brand identity.',
                c2Front: 'Professional Quality',
                c2Back: 'Our team of experienced editors employs advanced editing techniques to achieve professional-grade results that elevate the overall impact and effectiveness of your videos.',
                c3Front: 'Customization',
                c3Back: 'Whether you need simple edits or complex post-production effects, we tailor our editing services to suit your specific needs and budget, providing flexible solutions that meet your requirements.',
                c4Front: 'Efficiency and Timeliness',
                c4Back: 'We understand the importance of deadlines. Our editors work efficiently to deliver high-quality edits within agreed-upon timelines, allowing you to meet your project milestones and objectives.',
                ilu1PathLight: 'Video 1 bw.gif',
                ilu1PathDark: 'Video 1 bb.gif',
                ilu2PathLight: 'Video 2 bw.gif',
                ilu2PathDark: 'Video 2 bb.gif',
            },

            // // ContentWrite: 
            {
                query: 'ContentWrite',
                title: 'Content Writing',
                subTitle: 'Words That Connect, Engage, and Inspire',
                p1: "Unlock the power of words with GDCS's expert Content Writing service. Whether you need compelling website copy, engaging blog posts, informative articles, or captivating social media content, our team of talented writers is dedicated to delivering high-quality, customized content that resonates with your audience and achieves your business objectives.",
                p2: 'Our Content Writing service is designed to meet the diverse needs of businesses and individuals across various industries. From startups and small businesses to established enterprises and creative professionals, we offer a comprehensive range of writing solutions tailored to your specific requirements and goals.',
                p3: "With a focus on creativity, clarity, and precision, we craft content that not only informs and entertains but also inspires action and drives results. Whether you're looking to attract more visitors to your website, boost your search engine rankings, or establish thought leadership in your industry, our team has the expertise and experience to help you achieve your content marketing goals.",
                p4: "In the digital age, compelling content is the cornerstone of effective communication. With GDCS's Content Writing service, you can unlock the power of words to connect with your audience and achieve your business goals. Whether you need website copy, blog posts, or social media content, our team of experienced writers crafts engaging and informative content that resonates with your audience and drives results. From captivating headlines to persuasive calls-to-action, every word is carefully chosen to convey your message with clarity and impact. Partner with us for Content Writing, and let's tell your story in a way that inspires, educates, and engages your audience.",
                p5: "Whether you need a one-time project or ongoing content support, GDCS is your trusted partner for professional Content Writing. Let us help you tell your story, connect with your audience, and achieve your business goals through the power of words.",
                p6: "",
                cardHeading: 'Key features of our Content Writing service include:',
                c1Front: 'Customized Solutions',
                c1Back: 'We take the time to understand your brand voice, target audience, and communication objectives, ensuring that our content aligns seamlessly with your brand identity and messaging.',
                c2Front: 'Engaging Copy',
                c2Back: "Whether it's website pages, product descriptions, or promotional materials, we specialize in writing captivating copy that captures attention, sparks interest, and drives conversions.",
                c3Front: 'Thought Leadership',
                c3Back: 'We can help you establish thought leadership in your industry with well-researched and insightful articles, whitepapers, and case studies that showcase your expertise and authority.',
                c4Front: 'Social Media Content',
                c4Back: 'From catchy captions to engaging posts, we create social media content that resonates with your audience and sparks conversations, helping you build a strong and loyal following on popular platforms.',
                ilu1PathLight: 'Content 1 bw.gif',
                ilu1PathDark: 'Content 1 bb.gif',
                ilu2PathLight: 'Content 2 bw.gif',
                ilu2PathDark: 'Content 2 bb.gif',
            },

            // // GraphicsDesign: 
            {
                query: 'GraphicsDesign',
                title: 'Graphic Design',
                subTitle: 'Visual Solutions Tailored for You',
                p1: "Elevate your brand identity and communication with GDCS's professional Graphic Design service. Whether you need eye-catching visuals for your website, striking marketing materials, or memorable branding elements, our team of creative designers is dedicated to bringing your vision to life and making a lasting impression on your audience.",
                p2: 'Our Graphic Design service offers a comprehensive range of solutions tailored to meet the diverse needs of businesses and individuals across various industries. From startups and small businesses to established enterprises and creative professionals, we provide customized designs that reflect your unique personality, values, and objectives.',
                p3: "With a focus on creativity, aesthetics, and functionality, we craft designs that not only look stunning but also effectively communicate your message and drive engagement. Whether you're looking to launch a new product, promote an event, or revamp your brand identity, our team has the expertise and experience to deliver exceptional results that exceed your expectations.",
                p4: "Visuals are a powerful tool for communication, evoking emotions and conveying messages in an instant. With GDCS's Graphic Design service, you can harness the power of visual storytelling to elevate your brand and captivate your audience. Whether you need eye-catching graphics for your website, marketing materials, or social media, our team of talented designers specializes in creating custom designs that reflect your brand identity and leave a lasting impression. From sleek logos to stunning infographics, we combine creativity with strategic thinking to deliver designs that stand out in a crowded marketplace. Let GDCS be your partner in graphic design, and together, let's create visuals that inspire and engage your audience.",
                p5: "Whether you need a one-time design project or ongoing design support, GDCS is your trusted partner for professional Graphic Design. Let us help you stand out from the crowd, communicate effectively with your audience, and achieve your business goals through the power of design.",
                p6: "",
                cardHeading: 'Key features of our Graphic Design service include:',
                c1Front: 'Branding and Identity',
                c1Back: "Whether you're starting from scratch or looking to refresh your existing brand, we create logos, color palettes, and visual assets that represent your brand identity and leave a memorable impression on your audience.",
                c2Front: 'Marketing Collateral',
                c2Back: 'From brochures and flyers to posters and banners, we design marketing materials that stand out and effectively convey your message, helping you attract and engage your target audience.',
                c3Front: 'Web Graphics',
                c3Back: 'We create stunning visuals for your website, including banners, icons, infographics, and other web elements that enhance user experience and reinforce your brand identity.',
                c4Front: 'Social Media Graphics',
                c4Back: 'We design captivating graphics for your social media channels, including cover images, profile pictures, and post graphics that grab attention and drive engagement on popular platforms.',
                ilu1PathLight: 'Graphics 1 bw.gif',
                ilu1PathDark: 'Graphics 1 bb.gif',
                ilu2PathLight: 'Graphics 2 bw.gif',
                ilu2PathDark: 'Graphics 2 bb.gif',
            },
        ]
    }
]